<template>
    <div class="home">
        <div class="white-bg">
        <div class="zpanle">
            <div class="baccount-tit">    
                <h3 class="g-title">账户安全</h3>
            </div>
            <div class="panner-bd asafe-bd">
               <div class="modiysucc-box minht">
                   <ul class="modiysucc-list">
                       <li class="modiysucc-item">
                           <i class="jk-iconchenggong1"></i>
                           <span>修改成功</span>
                       </li>
                       <li>密码已经修改成功</li>
                       <li @click="toLogin()">
                          <el-row  >
                                <el-button  plain>重新登录</el-button>
                            </el-row>
                       </li>
                   </ul>
               </div>
            </div>    
        </div>
       </div>

    </div>
</template>
<script>
export default {
    name: "successPassword",
    metaInfo() {
        return {
            title: "修改成功 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
           accountForm:{
               pass:'',
               newpass:'',
               chkpass:''
           } 
        };
    },
    methods: {
        toLogin() {
            this.$router.push({name:'login'});
        }
    },
    created() {
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
</style>


